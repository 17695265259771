import React from 'react';

export default function (props) {
  let className = props.className || '';

  return (
    <div className={`page-loading ${className}`}>
      <div className="page-loading-loader"></div>
    </div>
  );
}
