import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';

import './assets/styles/styles.scss';
import './i18n';
import store from './reducers';
import App from './App';
import Notifications from './components/common/notifications';
import FileBar from './components/common/filebar';

const rootElement = document.getElementById('root');

ReactDOM.render(
  <Provider store={store}>
    <App />
    <Notifications />
    <FileBar />
  </Provider>,
  rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
