import axios from 'axios';

const tokenName = 'x-auth-token';
const token = window.localStorage.getItem(tokenName);
const userData = window.localStorage.getItem('user-data');

const initialState = {
  isAuthenticated: false,
  user: {
    name: '',
    surname: '',
    email: '',
  },
};

if (token) {
  axios.defaults.headers.common[tokenName] = localStorage.getItem(tokenName);
  initialState.isAuthenticated = true;
  initialState.token = token;
}

if (userData) {
  let obj = JSON.parse(userData);
  initialState.user = { ...obj };
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SIGN_IN':
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
      };

    case 'SIGN_OUT':
      return {
        isAuthenticated: false,
      };
    default:
      return state;
  }
};

export default authReducer;
