import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import cs from './cs.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'cs',
    resources: {
      cs: {
        translations: cs,
      },
    },
    debug: true,
    react: {
      useSuspense: false,
    },
  });

export default i18n;
