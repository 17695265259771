import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';

import { InputText } from '../../common/input';
import { Button } from '../../common/button';

import { createDocument, fetchDriveStructure } from '../../../actions/driveActions';
import { getToken } from '../../../actions/authActions';

class CreateFolder extends Component {
  formSubmit = (e) => {
    e.preventDefault();
    const { dispatch, match, handleClose } = this.props;
    //const data = getValues(e.target);
    const data = new FormData(e.target); // data from form

    if (match.params.id) {
      data.append('folder_id', match.params.id);
    }

    dispatch(createDocument(data, 'document')).then((id) => {
      handleClose();
      dispatch(fetchDriveStructure(match.params.id));
      window.open(`office/${id.toString()}/${getToken()}`);
    });
  };

  render() {
    const { t, handleClose } = this.props;

    return (
      <form className="form" onSubmit={this.formSubmit}>
        <InputText name="name" label="Název dokumentu" required />
        <div className="btn-group btn-group--right">
          <Button type="secondary" onClick={handleClose}>
            {t('close')}
          </Button>
          <Button type="primary" formAction="submit">
            {t('save')}
          </Button>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default compose(withRouter, withTranslation('translations'), connect(mapStateToProps, mapDispatchToProps))(CreateFolder);
