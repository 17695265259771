import axios from 'axios';
const tokenName = 'x-auth-token';

export const login = (formData) => {
  return (dispatch) => {
    deleteToken();

    return axios
      .post('authentication', {
        data: {
          user: {
            ...formData,
          },
        },
      })
      .then((response) => {
        setToken(response.data.data.token);

        if (formData.permanent) {
          window.localStorage.setItem(tokenName, response.data.data.token);
          window.localStorage.setItem('user-data', JSON.stringify(response.data.data.user));
        }

        dispatch({
          type: 'SIGN_IN',
          payload: response.data.data,
        });
      });
  };
};

export const logout = () => {
  return (dispatch) => {
    deleteToken();

    return dispatch({
      type: 'SIGN_OUT',
    });
  };
};

export const setToken = (token) => {
  axios.defaults.headers.common[tokenName] = token;
};

export const getToken = () => {
  return axios.defaults.headers.common[tokenName];
};

export const deleteToken = () => {
  axios.defaults.headers.common[tokenName] = null;
  delete axios.defaults.headers.common[tokenName];
  window.localStorage.removeItem(tokenName);
  window.localStorage.removeItem('user-data');
};

export default {
  login,
  logout,
};
