export const set = (id) => {
  return {
    type: 'SET',
    payload: id,
  };
};

export const showContextMenu = (payload) => {
  return {
    type: 'SHOW_CONTEXT_MENU',
    payload,
  };
};

export const hideContextMenu = () => {
  return {
    type: 'HIDE_CONTEXT_MENU',
  };
};

export const showDetailsPanel = (payload) => {
  return {
    type: 'SHOW_DETAILS_PANEL',
    payload,
  };
};

export const hideDetailsPanel = () => {
  return {
    type: 'HIDE_DETAILS_PANEL',
  };
};

export const toggleDetailsPanel = () => {
  return {
    type: 'TOGGLE_DETAILS_PANEL',
  };
};

export const toggleDriveList = () => {
  return {
    type: 'TOGGLE_DRIVE_LIST',
  };
};

export const showUploadBar = () => {
  return {
    type: 'SHOW_UPLOAD_BAR',
  };
};

export const hideUploadBar = () => {
  return {
    type: 'HIDE_UPLOAD_BAR',
  };
};

export const setPersistantItem = () => {
  return {
    type: 'SET_PERSISTANT_ITEM',
  };
};

export const removePersistantItem = () => {
  return {
    type: 'REMOVE_PERSISTANT_ITEM',
  };
};

export const setState = (payload) => {
  return {
    type: 'SET_STATE',
    payload,
  };
};

export const createNotification = (payload) => {
  return {
    type: 'CREATE_NOTIFICATION',
    payload,
  };
};

export const deleteNotification = (uid) => {
  return {
    type: 'DELETE_NOTIFICATION',
    uid,
  };
};

export default {
  set,
  setState,
};
