export default function (string) {
  string = string.replace('Z', '');
  const date = new Date(string);

  let day = date.getDate();
  let month = date.getMonth() + 1; // 0 to 11
  let year = date.getFullYear();
  let hours = date.getHours();
  let minutes = date.getMinutes();

  if (hours < 10) {
    hours = '0' + hours;
  }

  if (minutes < 10) {
    minutes = '0' + minutes;
  }

  return `${day}.${month}.${year} ${hours}:${minutes}`;
}
