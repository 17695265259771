import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

import { setState } from '../../actions/UIActions';

import CreateFolder from './bodies/createFolder';
import CreateDocument from './bodies/createDocument';
import CreateTable from './bodies/createTable';
import Edit from './bodies/edit';
import Remove from './bodies/remove';
import Permissions from './bodies/permissions';
import UploadFile from './bodies/uploadFile';
import UploadFolderImage from './bodies/uploadFolderImage';
import Share from './bodies/share';

class Popup extends Component {
  getContentByModalType = (type) => {
    let Body = null;

    switch (type) {
      case 'createFolder':
        Body = <CreateFolder handleClose={(e) => this.handleClose(e)} />;
        break;
      case 'createDocument':
          Body = <CreateDocument handleClose={(e) => this.handleClose(e)} />;
          break;
      case 'createTable':
          Body = <CreateTable handleClose={(e) => this.handleClose(e)} />;
          break;
      case 'edit':
        Body = <Edit handleClose={(e) => this.handleClose(e)} />;
        break;
      case 'remove':
        Body = <Remove handleClose={(e) => this.handleClose(e)} />;
        break;
      case 'permissions':
        Body = <Permissions handleClose={(e) => this.handleClose(e)} />;
        break;
      case 'share':
        Body = <Share handleClose={(e) => this.handleClose(e)} />;
        break;
      case 'uploadFile':
        Body = <UploadFile handleClose={(e) => this.handleClose(e)} />;
        break;
      case 'uploadFolderImage':
        Body = <UploadFolderImage handleClose={(e) => this.handleClose(e)} />;
        break;
      default:
        break;
    }

    return Body;
  };

  handleClose = (e) => {
    const { dispatch } = this.props;
    if (e) {
      e.preventDefault();
    }

    dispatch(
      setState({
        showPopup: null,
      })
    );
  };

  render() {
    const { t } = this.props;
    const { showPopup: type } = this.props.UI;

    if (type == null) {
      return null;
    }

    return (
      <div id="POPUP" className={`popup ${type ? 'popup--show' : null}`}>
        <div className={`popup__inner popup__inner--${type}`}>
          <div className="popup__header">
            <div className="popup__title">{t(`popup.${type}`)}</div>
            <div className="popup__close" onClick={(e) => this.handleClose(e)}></div>
          </div>
          <div className="popup__body">{this.getContentByModalType(type)}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default compose(withTranslation('translations'), connect(mapStateToProps, mapDispatchToProps))(Popup);
