import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';

import { getValues } from '../../../utils/form';
import { InputText, InputHidden } from '../../common/input';
import { Button } from '../../common/button';

import { editFolder, editFile, fetchDriveStructure, setLoading } from '../../../actions/driveActions';

class EditFolder extends Component {
  formSubmit = (e) => {
    e.preventDefault();
    const { dispatch, match, handleClose, drive } = this.props;
    const data = getValues(e.target);

    if (drive && drive.activeItem && drive.activeItem && drive.activeItem.type) {
      dispatch(editFile(data.name, data.id)).then(() => {
        dispatch(setLoading());
        handleClose();
        dispatch(fetchDriveStructure(match.params.id));
      });
    } else {
      dispatch(editFolder(data.name, data.id)).then(() => {
        dispatch(setLoading());
        handleClose();
        dispatch(fetchDriveStructure(match.params.id));
      });
    }
  };

  render() {
    const { t, handleClose } = this.props;
    const { id, name, type } = this.props.drive.activeItem;

    return (
      <form className="form" onSubmit={this.formSubmit}>
        {type ? null : null}
        <InputHidden name="id" value={id} />
        <InputText name="name" value={name} />
        <div className="btn-group btn-group--right">
          <Button type="secondary" onClick={handleClose}>
            {t('close')}
          </Button>
          <Button type="primary" formAction="submit">
            {t('save')}
          </Button>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default compose(withRouter, withTranslation('translations'), connect(mapStateToProps, mapDispatchToProps))(EditFolder);
