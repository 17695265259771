import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';

import { getValues } from '../../../utils/form';
import { InputText } from '../../common/input';
import { Button } from '../../common/button';

import { createFolder, fetchDriveStructure, setLoading } from '../../../actions/driveActions';

class CreateFolder extends Component {
  formSubmit = (e) => {
    e.preventDefault();
    const { dispatch, match, handleClose } = this.props;
    const data = getValues(e.target);

    dispatch(createFolder(data.name, match.params.id)).then(() => {
      dispatch(setLoading());
      handleClose();
      dispatch(fetchDriveStructure(match.params.id));
    });
  };

  render() {
    const { t, handleClose } = this.props;

    return (
      <form className="form" onSubmit={this.formSubmit}>
        <InputText name="name" required />
        <div className="btn-group btn-group--right">
          <Button type="secondary" onClick={handleClose}>
            {t('close')}
          </Button>
          <Button type="primary" formAction="submit">
            {t('save')}
          </Button>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default compose(withRouter, withTranslation('translations'), connect(mapStateToProps, mapDispatchToProps))(CreateFolder);
