import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import Notification from './notification';

class Notifications extends Component {
  render() {
    const { notifications } = this.props.UI;

    if (notifications.length < 1) {
      return null;
    }

    return (
      <div className="notifications">
        {Object.keys(notifications).map((id) => {
          return <Notification key={id} uid={id} type={notifications[id].type} message={notifications[id].message} />;
        })}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Notifications);
