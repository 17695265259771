import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import authReducer from './authReducer';
import driveReducer from './driveReducer';
import uploadReducer from './uploadReducer';
import UIReducer from './UIReducer';
import StatusBarReducer from './statusBarReducer';

const middlewares = [thunk];
const redux_devtools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

const allReducers = combineReducers({
  auth: authReducer,
  drive: driveReducer,
  upload: uploadReducer,
  UI: UIReducer,
  StatusBar: StatusBarReducer,
});

let createStoreFunc = createStore(allReducers, compose(applyMiddleware(...middlewares)));
if (process.env.REACT_APP_ENVIRONMENT === 'local') {
  createStoreFunc = createStore(allReducers, compose(applyMiddleware(...middlewares), redux_devtools));
}

export default createStoreFunc;
