import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

import PageLoading from '../components/common/pageLoading';
import Axios from 'axios';
import Script from '../hooks/Script';
import PageError from './pageError';

class PageOffice extends Component {
  state = {
    isLoading: true,
    content: null,
    fileId: null,
    scriptUrl: null,
    scriptCallback: null,
    isError: false,
  };

  componentDidMount() {
    let fileId = this.props && this.props.match && this.props.match.params && this.props.match.params.id;

    // Remove token and replace history
    window.history.replaceState(null, null, `/office/${fileId}`);

    this.setState({
      content: null,
      fileId: fileId,
    });

    if (!fileId) return;

    Axios.get(`office/type/${fileId}/view`)
      .then((response) => {
        this.renderOffice(response.data);
      })
      .catch(() => {
        this.setState({
          isError: true,
        });
      });
  }

  renderOffice(content) {
    var temp = document.createElement('div');
    temp.innerHTML = content;

    const script = temp.querySelector('#script');
    const callback = temp.querySelector('#callback');

    if (!script || !callback) return;

    this.setState({
      isLoading: false,
      scriptUrl: script.src,
      scriptCallback: callback.innerHTML,
    });
  }

  render() {
    const { isLoading, isError, scriptUrl, scriptCallback } = this.state;

    if (isError) {
      return <PageError />;
    }

    if (isLoading || !scriptUrl || !scriptCallback) {
      return <PageLoading />;
    }

    return (
      <Fragment>
        <div className="editor-wrapper">
          <div id="editor" className="editor"></div>
        </div>
        <Script src={scriptUrl} async={false} onLoad={scriptCallback}></Script>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default compose(withTranslation('translations'), connect(mapStateToProps, mapDispatchToProps))(PageOffice);
