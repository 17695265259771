import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { deleteNotification } from '../../actions/UIActions';
import { withTranslation } from 'react-i18next';

class Notification extends Component {
  state = {
    show: false,
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        show: true,
      });

      setTimeout(() => {
        this.removeNotification();
      }, 2000);
    }, 300);
  }

  removeNotification = (e) => {
    this.setState({
      show: false,
    });

    setTimeout(() => {
      this.props.dispatch(deleteNotification(this.props.uid));
    }, 300);
  };

  render() {
    const { show } = this.state;
    const { type, message, t } = this.props;

    return (
      <div className={`notification notification--${type} ${show ? 'notification--active' : ''}`}>
        <div className="notification-close" onClick={(e) => this.removeNotification()}></div>
        <span className="notification-title">{t(`notification.${type}`)}</span>
        <span className="notigication-message">{message}</span>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default compose(withTranslation('translations'), connect(mapStateToProps, mapDispatchToProps))(Notification);
