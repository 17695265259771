import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';

class PageError extends Component {
  render() {
    const { t } = this.props;

    return (
      <div className="page-grid page-grid--login">
        <div className="login">
          <div className="login-inner">
            <h2 className="mb-1 mt-0">{t('pageError.title')}</h2>
            <p className="text-secondary mt-0 mb-2">{t('pageError.subtitleText')}</p>
            <span className="text-secondary text-small mt-2">
              {t('pageError.forgottenPassword')} <Link to="/login">{t('pageError.clickHere')}</Link>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default compose(withTranslation('translations'), connect(mapStateToProps, mapDispatchToProps))(PageError);
