import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';

import Axios from 'axios';
import PageLoading from '../../common/pageLoading';
import { createNotification } from '../../../actions/UIActions';
import { InputText } from '../../common/input';
import { Button } from '../../common/button';

class Share extends Component {
  constructor(props) {
    super(props);
    this.refInput = React.createRef();

    this.state = {
      isLoaded: false,
      urlToken: null,
    };
  }

  componentDidMount() {
    const { dispatch, handleClose } = this.props;
    const { activeItem } = this.props.drive;
    let url;

    if (activeItem && activeItem.type) {
      // file
      url = `/file/${activeItem.id}/share-link`;
    } else {
      // folder
      url = `/folder/${activeItem.id}/share-link`;
    }

    Axios.post(url)
      .then((res) => {
        const { activeItem } = this.props.drive;
        let id;
        let type;
        let token = res.data.data.share_link.token;
        if (activeItem && activeItem.type) {
          // file
          type = 'file';
          id = res.data.data.share_link.file_id;
        } else {
          // folder
          type = 'folder';
          id = res.data.data.share_link.folder_id;
        }

        this.setState({
          isLoaded: true,
          urlToken: `${window.location.origin}/view-${type}/${id}/${token}`,
        });

        if (this.refInput && this.refInput.current) {
          this.refInput.current.click();

          this.props.dispatch(
            createNotification({
              type: 'info',
              message: 'Odkaz byl zkopírován do schránky',
            })
          );
        }
      })
      .catch(() => {
        dispatch(
          createNotification({
            type: 'error',
            message: 'Při vytváření sdíleného odkazu došlo k chybě.',
          })
        );
        handleClose();
      });
  }

  copy = (e) => {
    let input = e.target;
    input.select();
    document.execCommand('copy');
  };

  render() {
    const { handleClose, t } = this.props;
    const { isLoaded, urlToken } = this.state;

    const Input = React.forwardRef((props, ref) => <InputText innerRef={ref} {...props} />);

    if (isLoaded) {
      return (
        <>
          <p>Pro veřejné sdílení použijte tento odkaz:</p>
          <Input ref={this.refInput} readOnly defaultValue={urlToken} onClick={(e) => this.copy(e)} />
          <div className="btn-group btn-group--right">
            <Button type="secondary" onClick={handleClose}>
              {t('close')}
            </Button>
          </div>
        </>
      );
    }

    return <PageLoading />;
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default compose(withRouter, withTranslation('translations'), connect(mapStateToProps, mapDispatchToProps))(Share);
