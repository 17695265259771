const initialState = {
  show: false,
  downloading: {},
  uploading: {},
};

const StatusBarReducer = (state = initialState, action) => {
  let { payload, type } = action;

  switch (type) {
    case 'TOGGLE_STATUS_BAR':
      return {
        ...state,
        show: !state.show,
      };
    case 'HIDE_STATUS_BAR':
      return {
        ...state,
        show: false,
      };
    case 'CREATE_NEW_DOWNLOAD':
      return {
        ...state,
        show: true,
        downloading: {
          ...state.downloading,
          [payload.uniq]: {
            ...payload,
            loaded: 0,
            timestamp: payload.uniq,
          },
        },
      };

    case 'SET_DOWNLOAD_STATUS':
      return {
        ...state,
        downloading: {
          ...state.downloading,
          [payload.uniq]: {
            ...state.downloading[payload.uniq],
            loaded: payload.progressEvent.loaded,
            timestamp: payload.progressEvent.timeStamp,
          },
        },
      };

    case 'REMOVE_DOWNLOAD_BY_ID':
      delete state.downloading[payload];
      return {
        ...state,
      };

    case 'CREATE_NEW_UPLOAD':
      return {
        ...state,
        show: true,
      };

    case 'SET_UPLOAD_STATUS':
      return {
        ...state,
        uploading: {
          ...state.uploading,
          [payload.uniq]: {
            ...state.uploading[payload.uniq],
            size: payload.progressEvent.size,
            loaded: payload.progressEvent.loaded,
            timestamp: payload.progressEvent.timeStamp,
          },
        },
      };

    case 'REMOVE_UPLOAD_BY_ID':
      delete state.uploading[payload];
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default StatusBarReducer;
