import { useEffect } from 'react';

const useScript = (url, async, code, onLoad) => {
  useEffect(() => {
    const script = document.createElement('script');

    if (url) {
      script.src = url;
    }

    if (onLoad) {
      script.addEventListener('load', function () {
        // eslint-disable-next-line
        eval(onLoad);
      });
    }

    script.async = typeof async === 'undefined' ? true : async;
    if (code) {
      script.innerHTML = code;
    }

    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};

export default function Script({ src, async = true, code, onLoad }) {
  useScript(src, async, code, onLoad);

  return null; // Return null is necessary for the moment.
}
