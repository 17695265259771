import { hideUploadBar } from '../actions/UIActions';
import { uploadFiles, fetchDriveStructure } from '../actions/driveActions';

export const onDragOver = (that, e) => {
  e.preventDefault();
};

export const onDragEnter = (that, e) => {
  e.preventDefault();

  that.setState({
    isDragOver: true,
  });
};

export const onDragLeave = (that, e) => {
  e.preventDefault();

  that.setState({
    isDragOver: false,
  });
};

export const onDrop = (that, e) => {
  e.preventDefault();
  e.stopPropagation();

  const { match, id, dispatch } = that.props;

  dispatch(hideUploadBar());

  if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
    const files = e.dataTransfer.files;
    const data = new FormData();

    let pageId = id ? id : match.params.id;

    for (let i = 0; i < files.length; i++) {
      data.append('file[]', files[i]);
    }

    if (pageId) {
      data.append('folder_id', pageId);
    }

    e.dataTransfer.clearData();
    dispatch(uploadFiles(data)).then(() => {
      dispatch(fetchDriveStructure(match.params.id));
    });
  }

  that.setState({
    isDragOver: false,
  });
};
