import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';

import { getValues, usersPermissions } from '../../../utils/form';
import { InputHidden, InputCheckbox } from '../../common/input';
import { Button } from '../../common/button';
import { setPermissions } from '../../../actions/driveActions';
import Axios from 'axios';
import PageLoading from '../../common/pageLoading';

class Permissions extends Component {
  state = {
    permissionsList: null,
    usersList: null,
    isSubmitted: false,
  };

  componentDidMount() {
    var that = this;
    const { activeItem } = this.props.drive;
    let users;
    let permissions;
    let type = 'share_folder';
    if (activeItem && activeItem.type) {
      type = 'share_file';
      permissions = Axios.get('permission/file');
      users = Axios.get(`file/${activeItem.id}/permission`);
    } else {
      permissions = Axios.get('permission/folder');
      users = Axios.get(`folder/${activeItem.id}/permission`);
    }

    Axios.all([permissions, users]).then(
      Axios.spread((...responses) => {
        const permission = responses[0].data;
        const users = responses[1].data;
        that.setState({
          permissionsList: permission.data.permissions,
          usersList: users.data[type],
        });
      })
    );
  }

  formSubmit = (e) => {
    e.preventDefault();

    const { dispatch, drive, handleClose } = this.props;
    let values = getValues(e.target);
    let type = drive.activeItem.type;

    this.setState({
      isSubmitted: true,
    });

    dispatch(
      setPermissions({
        id: values.id,
        type: type,

        permissions: usersPermissions(values, this.state.usersList),
      })
    )
      .then(() => {
        handleClose();
      })
      .catch(() => {
        this.hasError();
      });
  };

  onCheckClick = (e) => {
    e.preventDefault();
    const item = e.target;
    const row = item.closest('tr');
    let after = false;

    if (!row) {
      return false;
    }

    const checkboxes = row.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach(function (element) {
      if (element !== item && after === false) {
        element.checked = true;
      }

      if (element !== item && after === true) {
        element.checked = false;
      }

      if (element === item) {
        after = true;
      }
    });
  };

  render() {
    const { permissionsList, usersList, isSubmitted } = this.state;
    const { t } = this.props;
    const { id } = this.props.drive.activeItem;

    if (permissionsList && usersList) {
      return (
        <>
          <form className="form" onSubmit={this.formSubmit}>
            <table className="permissions-table">
              <thead>
                <tr>
                  <th>{t('permissions.username')}</th>
                  {permissionsList.map((item) => {
                    return <th key={item.id}>{t(`permissions.${item.name}`)}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {usersList.map((user) => {
                  return (
                    <tr key={user.id} id={`row-${user.id}`}>
                      <td>
                        {user.name} {user.surname}
                        <br />
                        <span>{user.email}</span>
                      </td>
                      {user.permissions.map((permission) => {
                        return (
                          <td key={permission.id}>
                            <div className="table-cell-wrapper">
                              <InputCheckbox name={`perm.${user.id}.${permission.id}`} checked={permission.have} onInput={(e) => this.onCheckClick(e)} />
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <InputHidden name="id" value={id} />
            <div className="btn-group btn-group--right">
              <Button type="primary" formAction="submit" loading={isSubmitted}>
                {t('save')}
              </Button>
            </div>
          </form>
        </>
      );
    }

    return <PageLoading />;
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default compose(withRouter, withTranslation('translations'), connect(mapStateToProps, mapDispatchToProps))(Permissions);
