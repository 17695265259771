import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';

class Upload extends Component {
  render() {
    const { t } = this.props;
    const { showUploadBar } = this.props.UI;

    if (showUploadBar) {
      return <div className="upload-bar">{t('upload.canDrop')}</div>;
    }

    return null;
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default compose(withRouter, withTranslation('translations'), connect(mapStateToProps))(Upload);
