import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import PrivateRoute from './components/common/privateRoute';
import PageLogin from './pages/pageLogin';
import PageResetPassword from './pages/pageResetPassword';
import PageDrive from './pages/pageDrive';
import pageViewFile from './pages/pageViewFile';
import pageViewFolder from './pages/pageViewFolder';
import pageOffice from './pages/pageOffice';

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/login" exact component={PageLogin} />
          <Route path="/reset-password/:hash?" exact component={PageResetPassword} />
          <Route path="/view-folder/:id/:token" exact component={pageViewFolder} />
          <Route path="/view-file/:id/:token" exact component={pageViewFile} />
          <PrivateRoute path="/office/:id/:token?" exact component={pageOffice} />
          <PrivateRoute path="/:id?" component={PageDrive} />
        </Switch>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(App);
