import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';

import { showContextMenu } from '../../actions/UIActions';
import { setActiveItem, downloadFile } from '../../actions/driveActions';

import { onDragEnter, onDragOver, onDragLeave, onDrop } from '../../utils/upload';
import formattedDate from '../../utils/date';
import { baseUrl } from '../../api';
import { isOwner, getPermission } from '../../utils/permissions';
import Axios from 'axios';
import Loading from '../common/loading';

class Item extends Component {
  state = {
    isDragOver: false,
    hasThumbnail: false,
    thumbnailUrl: null,
    thumbnailError: false,
  };

  componentDidMount() {
    const { id, details } = this.props.item;

    var thumbnailsExts = ['jpg', 'jpeg', 'png', 'pdf'];
    if (details && details.extension && thumbnailsExts.includes(details.extension.toLowerCase())) {
      this.setState({
        hasThumbnail: true,
      });

      Axios({
        method: 'get',
        url: `img/${id}`,
        responseType: 'blob',
      })
        .then((response) => {
          let binaryData = [];
          binaryData.push(response.data);

          const urlCreator = window.URL || window.webkitURL;
          const imageUrl = urlCreator.createObjectURL(new Blob(binaryData, { type: 'image/jpeg' }));

          this.setState({
            hasThumbnail: true,
            thumbnailUrl: imageUrl,
            thumbnailError: false,
          });
        })
        .catch(() => {
          this.setState({
            hasThumbnail: true,
            thumbnailUrl: null,
            thumbnailError: true,
          });
        });
    }
  }

  handleOnClick = () => {
    const { item } = this.props;
    this.props.dispatch(setActiveItem(item));
  };

  handleOnDoubleClick = () => {
    const { history, item, match, mode, dispatch } = this.props;
    let url;
    let token = match.params.token;

    if (item && item.type) {
      if (token) {
        dispatch(
          downloadFile(
            item.id,
            {
              item,
            },
            token
          )
        );
      }
      return false;
    }

    // IF FOLDER
    url = item.id.toString();
    if (mode === 'view') {
      url = `/view-folder/${item.id.toString()}/${token}`;
    }

    history.push(url);
    return false;
  };

  handleContextMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { dispatch, item } = this.props;

    dispatch(setActiveItem(item));
    dispatch(
      showContextMenu({
        x: e.clientX,
        y: e.clientY,
        item: item,
      })
    );
  };

  render() {
    const { isDragOver, thumbnailUrl, hasThumbnail, thumbnailError } = this.state;
    const { item, drive, UI, mode } = this.props;
    const { activeItem } = drive;

    const view = UI.driveTypeGrid ? 'grid' : 'list';
    const type = item && item.details && item.details.type ? item.details.type : this.props.type;
    const isActive = activeItem && activeItem.id === item.id ? true : false;
    let isDroppable = mode !== 'view' && type === 'folder'; // TODO: permisions and folder
    if (isDroppable === true) {
      let permissions = item.permissions ? item.permissions : [];
      isDroppable = isOwner(this.props, item) || getPermission(permissions, 'create');
    }

    if (view === 'grid') {
      return (
        <>
          <div
            className={`item item--${type}${isActive ? ' is-active' : ''}${
              isDragOver && isDroppable ? ' is-drag-over' : ''
            }`}
            onDragOver={isDroppable ? (e) => onDragOver(this, e) : null}
            onDragEnter={isDroppable ? (e) => onDragEnter(this, e) : null}
            onDragLeave={isDroppable ? (e) => onDragLeave(this, e) : null}
            onDrop={isDroppable ? (e) => onDrop(this, e) : null}
          >
            <div
              className="item-inner"
              onClick={() => this.handleOnClick()}
              onDoubleClick={() => this.handleOnDoubleClick()}
              onContextMenu={(e) => this.handleContextMenu(e)}
            >
              {item.details.image_url && (
                <div className={`item-image`}>
                  <img src={`${baseUrl}${item.details.image_url}`} alt="" />
                  <div className="item-image__description">{item.name}</div>
                </div>
              )}

              {hasThumbnail && (
                <div className={`item-image`}>
                  {!thumbnailUrl && !thumbnailError && <Loading />}
                  {thumbnailError && <div className="item-image__error">Obrázek se nepodařilo vygenerovat.</div>}
                  {thumbnailUrl && <img src={thumbnailUrl} />}
                </div>
              )}

              <div className="item-info">
                {type && (
                  <div
                    className={`item-icon item-icon--${type}${
                      item.details.extension ? ` item-ext-${item.details.extension}` : ''
                    }`}
                  ></div>
                )}
                <div className="drive-item-title">
                  {item.name}
                  {type === 'file' && `.${item.details.extension}`}
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        <tr
          className={`item item--${type}${isActive ? ' is-active' : ''}${
            isDragOver && isDroppable ? ' is-drag-over' : ''
          }`}
          onDragOver={isDroppable ? (e) => onDragOver(this, e) : null}
          onDragEnter={isDroppable ? (e) => onDragEnter(this, e) : null}
          onDragLeave={isDroppable ? (e) => onDragLeave(this, e) : null}
          onDrop={isDroppable ? (e) => onDrop(this, e) : null}
          onClick={() => this.handleOnClick()}
          onDoubleClick={() => this.handleOnDoubleClick()}
          onContextMenu={(e) => this.handleContextMenu(e)}
        >
          <td>
            <div className="item-info">
              {type && (
                <div
                  className={`item-icon item-icon--${type}${
                    item.details.extension ? ` item-ext-${item.details.extension}` : ''
                  }`}
                ></div>
              )}
              {item.name}
              {type === 'file' && `.${item.details.extension}`}
            </div>
          </td>
          <td>{formattedDate(item.details.date_created)}</td>
          <td>{formattedDate(item.details.date_updated)}</td>
        </tr>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Item);
