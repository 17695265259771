const initialState = {
  isUploading: false,
  parent: null,
  fileList: [],
};

const uploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'START_UPLOAD':
      const { parent, files } = action.payload;

      const fileList = [];
      for (let i = 0; i < files.length; i++) {
        if (!files[i].name) return;
        fileList.push(files[i]);
      }

      return {
        ...state,
        isUploading: true,
        fileList,
        parent,
      };

    case 'STOP_UPLOAD':
      return {
        ...state,
        isUploading: false,
        fileList: [],
        parent: null,
      };
    default:
      return state;
  }
};

export default uploadReducer;
