import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

import logo from '../assets/images/logo.svg';
import PageLoading from '../components/common/pageLoading';
import { Button } from '../components/common/button';

import { downloadFileAnonymously } from '../actions/driveActions';

class PageView extends Component {
  state = {
    isLoading: true,
    btnLoading: false,
    isDownloaded: false,
    isDownloading: false,
    file: {
      id: null,
      token: null,
    },
  };

  componentDidMount() {
    const { params } = this.props.match;
    this.setState({
      isLoading: false,
      file: {
        id: params.id,
        token: params.token,
      },
    });
  }

  download = (e) => {
    this.setState({
      btnLoading: true,
      isDownloading: true,
    });

    this.props.dispatch(downloadFileAnonymously(this.state.file.id, this.state.file.token)).then(() => {
      this.setState({
        btnLoading: false,
        isDownloaded: true,
        isDownloading: false,
      });
    });

    // TODO: do download
  };

  render() {
    const { isLoading } = this.state;

    if (isLoading) {
      return <PageLoading />;
    }

    return (
      <Fragment>
        <div className="header">
          <div className="header-row header-row--light">
            <div className="header-logo-wrapper">
              <img className="header-logo" alt="logo" src={logo} />
            </div>
          </div>
        </div>
        <div className="page-content">
          <div className="public-view">
            <h2>RP Disk</h2>

            {this.state.isDownloading && (
              <>
                <PageLoading className="mb-3" />
                <p>Soubor se právě stahuje, nezavírejte prohlížeč.</p>
              </>
            )}

            {this.state.isDownloaded && <p>Soubor byl úspěšně stažen.</p>}

            {!this.state.isDownloaded && !this.state.isDownloading && (
              <>
                <p>Pro stažení souboru klikněte na tlačítko.</p>
                <Button type="primary" loading={this.state.btnLoading} onClick={(e) => this.download(e)}>
                  Stáhnout soubor
                </Button>
              </>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default compose(withTranslation('translations'), connect(mapStateToProps, mapDispatchToProps))(PageView);
