import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import { InputText } from '../components/common/input';
import { Button } from '../components/common/button';

import { getValues } from '../utils/form';
import Axios from 'axios';

class PageResetPassword extends Component {
  state = {
    loading: false,
    loginError: false,
    formSubmitted: false,
  };

  formResetSubmit = (e) => {
    e.preventDefault();
    const formValues = getValues(e.target);

    this.setState({
      loading: true,
    });

    Axios.post('forgotten-password', {
      data: {
        user: {
          email: formValues.email,
        },
      },
    })
      .then(() => {
        this.setState({
          loading: false,
          formSubmitted: true,
        });
      })
      .catch(() => {
        this.hasError();
      });
  };

  formPasswordSubmit = (e) => {
    e.preventDefault();
    const { match } = this.props;
    const formValues = getValues(e.target);

    this.setState({
      loading: true,
    });

    if (formValues.password !== formValues.passwordRepeat) {
      return this.hasError();
    }

    Axios.post('change-password', {
      data: {
        token: match.params.hash,
        password: {
          new_password: formValues.password,
          new_password_repeat: formValues.passwordRepeat,
        },
      },
    })
      .then(() => {
        this.setState({
          loading: false,
          formSubmitted: true,
        });
      })
      .catch(() => {
        this.hasError();
      });
  };

  hasError = () => {
    this.setState({
      loading: false,
      loginError: true,
    });

    setTimeout(() => {
      this.setState({
        loginError: false,
      });
    }, 3500);
  };

  render() {
    const { t, match } = this.props;
    const { loading, loginError, formSubmitted } = this.state;

    if (match && match.params && match.params.hash) {
      return (
        <div className="page-grid page-grid--login">
          <div className="login">
            <div className="login-inner">
              <h2 className="mb-1">{t('pageResetPassword_2.title')}</h2>
              {formSubmitted && (
                <p className="text-secondary mt-0 mb-2">
                  {t('pageResetPassword_2.subtitleTextSuccess2')} <Link to="/login">{t('pageResetPassword_2.subtitleTextSuccessBack')} </Link>
                </p>
              )}
              {!formSubmitted && (
                <>
                  <p className="text-secondary mt-0 mb-2">{t('pageResetPassword_2.subtitleText')}</p>
                  <form className="form" onSubmit={(e) => this.formPasswordSubmit(e)}>
                    {loginError && <div className="form-error">{t('pageResetPassword_2.formError')}</div>}
                    <InputText required type="password" name="password" label={t('pageResetPassword_2.password')} autoComplete="new-password" />
                    <InputText required type="password" name="passwordRepeat" label={t('pageResetPassword_2.passwordRepeat')} autoComplete="new-password" />
                    <div className="btn-group btn-group--right">
                      <Button type="primary" loading={loading} formAction="submit">
                        {t('pageResetPassword_2.submit')}
                      </Button>
                    </div>
                    <span className="text-secondary text-small mt-2">
                      {t('pageResetPassword.rememberIt')} <Link to="/login">{t('pageResetPassword.backToLogin')}</Link>
                    </span>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="page-grid page-grid--login">
        <div className="login">
          <div className="login-inner">
            <h2 className="mb-1">{t('pageResetPassword.title')}</h2>
            {formSubmitted && <p className="text-secondary mt-0 mb-2">{t('pageResetPassword.subtitleTextSuccess')}</p>}
            {!formSubmitted && (
              <>
                <p className="text-secondary mt-0 mb-2">{t('pageResetPassword.subtitleText')}</p>
                <form className="form" onSubmit={(e) => this.formResetSubmit(e)}>
                  {loginError && <div className="form-error">{t('pageResetPassword.formError')}</div>}
                  <InputText required type="email" name="email" label={t('pageResetPassword.email')} autoComplete="email" />
                  <div className="btn-group btn-group--right">
                    <Button type="primary" loading={loading} formAction="submit">
                      {t('pageResetPassword.submit')}
                    </Button>
                  </div>
                  <span className="text-secondary text-small mt-2">
                    {t('pageResetPassword.rememberIt')} <Link to="/login">{t('pageResetPassword.backToLogin')}</Link>
                  </span>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default compose(withTranslation('translations'), connect(mapStateToProps, mapDispatchToProps))(PageResetPassword);
