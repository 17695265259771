import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

class FileBar extends Component {
  render() {
    return null;
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(FileBar);
