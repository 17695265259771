import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

// components
import DriveWrapper from './driveWrapper';
import DetailsPanel from './detailsPanel';

class Drive extends Component {
  render() {
    const { drive, loading, share, mode, canCreate } = this.props;

    if (loading) {
      return (
        <div className="page-loading">
          <div className="page-loading-loader"></div>
        </div>
      );
    }

    return (
      <div className="drive">
        <div className="drive-items">
          <DriveWrapper shared={share} folders={drive.folders} files={drive.files} mode={mode} canCreate={canCreate} />
        </div>
        <DetailsPanel />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default compose(withTranslation('translations'), connect(mapStateToProps, mapDispatchToProps))(Drive);
