import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import { setState, toggleDriveList } from '../../actions/UIActions';
import { logout } from '../../actions/authActions';
import StatusBar from '../common/statusBar';

import logoRP from '../../assets/images/logo.svg';
import logoExternity from '../../assets/images/logo-externity.svg';

import { Tooltip } from './tooltip';

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userControls: false,
    };

    this.refToggler = React.createRef();
    this.refControls = React.createRef();
  }

  componentDidMount() {
    const { refToggler: ref1, refControls: ref2 } = this;

    document.addEventListener('click', (e) => {
      if (ref1 && ref1.current && ref2 && ref2.current && !ref1.current.contains(e.target) && !ref2.current.contains(e.target)) {
        this.setState({
          userControls: false,
        });
      }
    });
  }

  handleDetailPanelToggle = () => {
    const { dispatch } = this.props;
    const { showDetailsPanel: panelState } = this.props.UI;

    dispatch(
      setState({
        showDetailsPanel: !panelState,
      })
    );
  };

  handleDriveListToggle = () => {
    this.props.dispatch(toggleDriveList());
  };

  toggleUserPanel = () => {
    this.setState({
      userControls: !this.state.userControls,
    });
  };

  render() {
    const { userControls } = this.state;
    const { dispatch, drive, UI, auth, mode, match } = this.props;
    const { paths } = drive;
    const { driveTypeGrid } = UI;

    const name = auth && auth.user && auth.user.name ? auth.user.name : '';
    const surname = auth && auth.user && auth.user.surname ? auth.user.surname : '';

    let breadcrumb = null;
    let breadcrumbIteration = 0;
    if (paths) {
      breadcrumb = Object.keys(paths).map((key) => {
        breadcrumbIteration += 1;
        let name = paths[key].name;
        let id = paths[key].id;
        let url = id !== 'null' ? id.toString() : '/';
        if (mode === 'view') {
          url = `/view-folder/${id.toString()}/${match.params.token}`;
        }

        return (
          <Fragment key={id.toString()}>
            {breadcrumbIteration === 1 ? (
              <Tooltip content="Zpět na domovskou stránku">
                <Link to={url} className={`breadcrumb__item breadcrumb__item--home`}>
                  {name}
                </Link>
              </Tooltip>
            ) : (
              <Link to={url} className={`breadcrumb__item`}>
                {name}
              </Link>
            )}

            <span className="breadcrumb__item-divider">/</span>
          </Fragment>
        );
      });
    }

    return (
      <div className={`header ${mode === 'view' ? 'header--view' : ''}`}>
        <div className="header-row header-row--light">
          <div className="header-logo-wrapper">
            <img className={`header-logo${process.env.REACT_APP_ENVIRONMENT === 'externity' ? ' header-logo-externity' : ''}`} alt="logo" src={process.env.REACT_APP_ENVIRONMENT === 'externity' ? logoExternity : logoRP} />
          </div>
          <div className="header-finder">
            <input type="text" name="search" placeholder="Hledej..." autoComplete="off" />
          </div>

          <div className="header-panel">
            <StatusBar />
            {mode !== 'view' ? (
              <>
                <div className={`header-user${userControls ? ' is-active' : ''}`} onClick={() => this.toggleUserPanel()} ref={this.refToggler}>
                  <div>
                    {name} {surname}
                  </div>
                  <div className="header-user-avatar">
                    {name.substring(0, 1)}
                    {surname.substring(0, 1)}
                  </div>
                </div>
                {userControls && (
                  <div className="header-panel-controls" ref={this.refControls}>
                    <div className="header-panel-controls__item" onClick={() => dispatch(logout())}>
                      Odhlásit se
                    </div>
                  </div>
                )}
              </>
            ) : (
              ''
            )}
          </div>
        </div>

        <div className="header-row">
          <div className="breadcrumb">{breadcrumb}</div>
          <div className="header__icons">
            <Tooltip content="Přepnout layout">
              <div className={`header__icon${driveTypeGrid ? ' header__icon--list' : ' header__icon--grid'}`} onClick={() => this.handleDriveListToggle()}></div>
            </Tooltip>

            <Tooltip content="Informační panel">
              <div className="header__icon header__icon--details" onClick={() => this.handleDetailPanelToggle()}></div>
            </Tooltip>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Header);
