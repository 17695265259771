import React from 'react';

export function Button(props) {
  let { onClick, type, formAction, size, disabled, loading, children: text, ...rest } = props;

  let className = `btn`;

  if (type) {
    className += ` btn--${type}`;
  }

  if (size) {
    className += ` btn--${size}`;
  }

  if (disabled) {
    className += ` is-disabled`;
  }

  if (loading) {
    className += ` is-loading`;
    disabled = true;
  }

  return (
    <button className={className} onClick={onClick} disabled={disabled} type={`${formAction ? 'submit' : 'button'}`} {...rest}>
      {text}
    </button>
  );
}

export function ButtonLink(props) {
  return null;
}
