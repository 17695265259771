import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { setToken } from '../../actions/authActions';

class PrivateRoute extends Component {
  render() {
    const { isAuthenticated } = this.props.auth;
    const { token } = this.props.computedMatch.params;

    if (token) {
      setToken(token);
    }

    if (!isAuthenticated && !token) {
      return <Redirect to="/login"></Redirect>;
    }

    return <Route {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
