import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';

import Item from './driveItem';
import { showContextMenu } from '../../actions/UIActions';
import { onDragEnter, onDragOver, onDragLeave, onDrop } from '../../utils/upload';

class DriveWrapper extends Component {
  state = {
    isDragOver: false,
  };

  createItems = (items, type) => {
    const { mode } = this.props;
    let result = [];

    if (items) {
      result = Object.keys(items).map((key) => {
        return <Item key={key} id={items[key].id} item={items[key]} type={type} mode={mode} />;
      });
    }

    return result;
  };

  handleContextMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { dispatch } = this.props;

    dispatch(
      showContextMenu({
        item: false,
        x: e.clientX,
        y: e.clientY,
      })
    );
  };

  render() {
    const { isDragOver } = this.state;
    const { UI, drive, mode, canCreate } = this.props;

    const view = UI.driveTypeGrid ? 'grid' : 'list';

    const folders = this.createItems(drive.folders, 'folder');
    const files = this.createItems(drive.files, 'file');

    let canDrop = true;
    if (mode === 'view' || !canCreate) {
      canDrop = false;
    }

    let sharedFolders = [];
    let sharedFiles = [];
    if (drive.share) {
      sharedFolders = this.createItems(drive.share.folders, 'folder');
      sharedFiles = this.createItems(drive.share.files, 'file');
    }

    let hasShared = sharedFolders.length + sharedFiles.length;

    if (folders.length === 0 && files.length === 0 && hasShared === 0) {
      return (
        <div className={`drive-wrapper drive-wrapper--${view}`} onContextMenu={(e) => this.handleContextMenu(e)}>
          <div
            className={`drive-group drive-group--empty drive-group--droppable${isDragOver && canDrop ? ' is-drag-over' : ''}`}
            onDragOver={canDrop ? (e) => onDragOver(this, e) : null}
            onDragEnter={canDrop ? (e) => onDragEnter(this, e) : null}
            onDragLeave={canDrop ? (e) => onDragLeave(this, e) : null}
            onDrop={canDrop ? (e) => onDrop(this, e) : null}>
            <span className="empty-state">V této složce zatím nemáte žádné soubory ani složky.</span>
          </div>
        </div>
      );
    }

    return (
      <div className={`drive-wrapper drive-wrapper--${view}`} onContextMenu={(e) => this.handleContextMenu(e)}>
        {view === 'grid' && (
          <>
            {hasShared > 0 && (
              <div className="drive-group">
                <div className="drive-group-title">Sdílené</div>
                <div className="drive-grid">
                  {sharedFolders} {sharedFiles}
                </div>
              </div>
            )}

            {folders.length > 0 && (
              <div className="drive-group">
                <div className="drive-group-title">Složky</div>
                <div className="drive-grid">{folders}</div>
              </div>
            )}

            {files.length > 0 && (
              <div
                className={`drive-group drive-group--droppable${isDragOver && canDrop ? ' is-drag-over' : ''}`}
                onDragOver={canDrop ? (e) => onDragOver(this, e) : null}
                onDragEnter={canDrop ? (e) => onDragEnter(this, e) : null}
                onDragLeave={canDrop ? (e) => onDragLeave(this, e) : null}
                onDrop={canDrop ? (e) => onDrop(this, e) : null}>
                <div className="drive-group-title">Soubory</div>
                <div className="drive-grid">{files}</div>
              </div>
            )}
          </>
        )}

        {view === 'list' && (
          <>
            <table className="item-table">
              <thead>
                <tr>
                  <th>Název</th>
                  <th>Vytvořeno</th>
                  <th>Upraveno</th>
                </tr>
              </thead>
              <tbody>
                {hasShared > 0 && (
                  <>
                    {sharedFolders} {sharedFiles}
                  </>
                )}
                {folders}
                {files}
              </tbody>
            </table>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default compose(withRouter, withTranslation('translations'), connect(mapStateToProps, mapDispatchToProps))(DriveWrapper);
