const initialState = {
  activeItem: null,
  loading: true,
  folders: [],
  files: [],
  share: {
    files: [],
    folders: [],
  },
};

const driveReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ACTIVE_ITEM':
      return {
        ...state,
        activeItem: action.payload,
      };

    case 'REMOVE_ACTIVE_ITEM':
      return {
        ...state,
        activeItem: null,
      };

    case 'REMOVE_DATA':
      return {
        ...initialState,
      };

    case 'SET_LOADING':
      return {
        ...state,
        loading: true,
      };

    case 'FETCH_DRIVE_STRUCTURE':
      return {
        activeItem: null,
        loading: false,
        ...action.payload.data,
      };

    default:
      return state;
  }
};

export default driveReducer;
