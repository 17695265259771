export const toggleStatusBar = () => {
  return {
    type: 'TOGGLE_STATUS_BAR',
  };
};

export const hideStatusBar = () => {
  return {
    type: 'HIDE_STATUS_BAR',
  };
};

// Download
export const createNewDownload = (payload) => {
  return {
    type: 'CREATE_NEW_DOWNLOAD',
    payload,
  };
};

export const setDownloadState = (payload) => {
  return {
    type: 'SET_DOWNLOAD_STATUS',
    payload,
  };
};

export const removeDownloadItem = (payload) => {
  return {
    type: 'REMOVE_DOWNLOAD_BY_ID',
    payload,
  };
};

// Upload
export const createNewUpload = () => {
  return {
    type: 'CREATE_NEW_UPLOAD',
  };
};

export const setUploadState = (payload) => {
  return {
    type: 'SET_UPLOAD_STATUS',
    payload,
  };
};

export const removeUploadItem = (payload) => {
  return {
    type: 'REMOVE_UPLOAD_BY_ID',
    payload,
  };
};
