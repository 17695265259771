import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

import { hideContextMenu, showUploadBar, hideUploadBar, setPersistantItem, removePersistantItem, setState } from '../actions/UIActions';
import { removeActiveItem, fetchDriveStructure, setLoading } from '../actions/driveActions';

import Header from '../components/common/header';
import Upload from '../components/common/upload';
import Portal from '../components/common/portal';
import ContextMenu from '../components/drive/contextMenu';

import Popup from '../components/popup/popup';
import Drive from '../components/drive/drive';
import { hasPermission } from '../utils/permissions';

class PageDrive extends Component {
  constructor(props) {
    super(props);

    this.state = {
      actionMenu: false,
    };

    this.refToggler = React.createRef();
    this.refControls = React.createRef();
  }

  handleClickOut = (e) => {
    const { dispatch, drive } = this.props;

    const contextMenu = document.querySelector('#CONTEXT_MENU');
    const onDetailPanelToggler = e.target.classList.contains('drive-panel') || e.target.closest('.header__icon--details');
    const onInfoPanel = e.target.classList.contains('drive-panel') || e.target.closest('#DRIVE_PANEL');
    const onItem = e.target.classList.contains('item') || e.target.closest('.item');
    const popup = document.querySelector('#POPUP');

    // CLICK INTO CONTEXT MENU
    if (contextMenu && contextMenu.contains(e.target)) {
      dispatch(setPersistantItem());
      return false;
    }

    if (onDetailPanelToggler || onItem) {
      return false;
    }

    // pokud je context menu ale klikám mimo něj
    if (contextMenu && !contextMenu.contains(e.target)) {
      dispatch(hideContextMenu());
    }

    // pokud nemám popup a poté neklikám ani na drive-item nebo do drive-item
    if (drive.activeItem && !popup && !onItem && !onInfoPanel) {
      dispatch(removeActiveItem());
      dispatch(removePersistantItem());
    }
  };

  handleResize = () => {
    const { dispatch } = this.props;
    const { contextMenu } = this.props.UI;
    if (contextMenu.show) {
      dispatch(hideContextMenu());
    }
  };

  handleDragEnter = (e) => {
    e.preventDefault();
    document.body.classList.add('is-dragging');

    if (!this.props.UI.showUploadBar) {
      this.props.dispatch(showUploadBar());
    }
  };

  handleDragLeave = (e) => {
    e.preventDefault();
    //document.body.classList.remove('is-dragging');
    if (!e.clientX && !e.clientY && this.props.UI.showUploadBar) {
      this.props.dispatch(hideUploadBar());
    }
  };

  handleDragEnd = (e) => {
    document.body.classList.remove('is-dragging');
    this.props.dispatch(hideUploadBar());
  };

  handleOnDrop = (e) => {
    document.body.classList.remove('is-dragging');
    this.props.dispatch(hideUploadBar());
  };

  showPopup = (type) => {
    this.setState({
      actionMenu: false,
    });

    this.props.dispatch(setState({ showPopup: type }));
  };

  toggleActionMenu = () => {
    this.setState({
      actionMenu: !this.state.actionMenu,
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { params } = this.props.match;
    if (nextProps.match.params.id !== params.id) {
      this.fetchData(nextProps.match.params.id);
    }
  }

  componentDidMount() {
    const { refToggler: ref1, refControls: ref2 } = this;

    document.addEventListener('click', (e) => {
      if (ref1 && ref1.current && ref2 && ref2.current && !ref1.current.contains(e.target) && !ref2.current.contains(e.target)) {
        this.setState({
          actionMenu: false,
        });
      }
    });

    this.fetchData(this.props.match.params.id);
    document.addEventListener('mousedown', (e) => this.handleClickOut(e));
    document.addEventListener('dragenter', (e) => this.handleDragEnter(e));
    document.addEventListener('dragleave', (e) => this.handleDragLeave(e));
    document.addEventListener('dragend', (e) => this.handleDragEnd(e));
    document.addEventListener('drop', (e) => this.handleOnDrop(e));
    window.addEventListener('resize', () => this.handleResize());
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', (e) => this.handleClickOut(e));
    document.removeEventListener('dragenter', (e) => this.handleDragEnter(e));
    document.removeEventListener('dragleave', (e) => this.handleDragLeave(e));
    document.removeEventListener('dragend', (e) => this.handleDragEnd(e));
    document.removeEventListener('drop', (e) => this.handleOnDrop(e));
    window.removeEventListener('resize', () => this.handleResize());
  }

  fetchData(id) {
    const { dispatch } = this.props;

    dispatch(setLoading());
    dispatch(fetchDriveStructure(id));
  }

  render() {
    const { actionMenu } = this.state;
    const { folders, files, loading: isLoading, share, permissions } = this.props.drive;
    const { contextMenu, showPopup } = this.props.UI;

    let canCreate = !permissions || permissions.length === 0 ? true : hasPermission(permissions, 'create');

    return (
      <Fragment>
        <Header />
        <div className="page-content">
          <Drive folders={folders} files={files} shared={share} loading={isLoading} canCreate={canCreate} />
        </div>

        {canCreate && (
          <>
            <div className="action-bar" ref={this.refToggler} onClick={(e) => this.toggleActionMenu()}></div>
            {actionMenu && (
              <div className="action-items" ref={this.refControls}>
                <div className="action-item" onClick={(e) => this.showPopup('uploadFile')}>
                  Nahrát soubor
                </div>
                <div className="action-item" onClick={(e) => this.showPopup('createTable')}>
                  Vytvořit novou tabulku
                </div>
                <div className="action-item" onClick={(e) => this.showPopup('createDocument')}>
                  Vytvořit nový dokument
                </div>
                <div className="action-item" onClick={(e) => this.showPopup('createFolder')}>
                  Vytvořit složku
                </div>
              </div>
            )}
          </>
        )}

        <Portal>
          {contextMenu.show && <ContextMenu canCreate={canCreate} />}
          <Popup type={showPopup} />
        </Portal>
        {canCreate && <Upload />}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default compose(withTranslation('translations'), connect(mapStateToProps, mapDispatchToProps))(PageDrive);
