import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';

import { InputFile } from '../../common/input';
import { Button } from '../../common/button';

import { fetchDriveStructure, uploadFiles } from '../../../actions/driveActions';

class CreateFolder extends Component {
  formSubmit = (e) => {
    e.preventDefault();
    const { dispatch, match, handleClose } = this.props;
    const data = new FormData(e.target); // data from form

    if (match.params.id) {
      data.append('folder_id', match.params.id);
    }

    dispatch(uploadFiles(data)).then(() => {
      dispatch(fetchDriveStructure(match.params.id));
    });

    handleClose();
  };

  render() {
    const { t, handleClose } = this.props;

    return (
      <form className="form" onSubmit={this.formSubmit} encType="multipart/form-data">
        <InputFile name="file[]" multiple />
        <div className="btn-group btn-group--right">
          <Button type="secondary" onClick={handleClose}>
            {t('close')}
          </Button>
          <Button type="primary" formAction="submit">
            {t('save')}
          </Button>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default compose(withRouter, withTranslation('translations'), connect(mapStateToProps, mapDispatchToProps))(CreateFolder);
