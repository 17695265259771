export const getValues = (element) => {
  const formData = new FormData(element);
  const obj = {};
  for (var pair of formData.entries()) {
    obj[pair[0]] = pair[1];
  }

  return obj;
};

export const usersPermissions = (obj, usersList) => {
  let users = [];

  Object.keys(usersList).forEach((key) => {
    users[usersList[key].id.toString()] = {
      id: usersList[key].id.toString(),
      permissions: [],
    };
  });

  Object.keys(obj).forEach((key) => {
    const keyArr = key.split('.');

    if (keyArr[0] !== 'perm') {
      return false;
    }

    const userId = keyArr[1];
    const permKey = keyArr[2];

    if (!users[userId]) {
      users[userId] = {
        id: userId,
        permissions: [],
      };
    }

    if (users[userId]) {
      users[userId].permissions.push({
        id: permKey,
      });
    }
  });

  // Fix array keys
  let arr = [];
  users.forEach((user) => {
    arr.push(user);
  });

  return arr;
};
