import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';

import { setState, hideContextMenu, toggleDetailsPanel } from '../../actions/UIActions';
import { downloadFile } from '../../actions/driveActions';
import { getPermission, isOwner } from '../../utils/permissions';
import { getToken } from '../../actions/authActions';

class ContextMenu extends Component {
  state = {
    permissions: [],
    isOwner: false,
    position: {
      top: 0,
      left: 0,
    },
  };

  handleClickItem = (type) => {
    const { dispatch, drive, history } = this.props;
    let id = null;
    if (drive && drive.activeItem && drive.activeItem.id) {
      id = drive.activeItem.id;
    }

    switch (type) {
      case 'open':
        if (id) {
          history.push(id.toString());
        }
        break;
      case 'download':
        if (id) {
          dispatch(downloadFile(id, { item: drive.activeItem }));
        }
        break;
      case 'editor':
        if (id) {
          window.open(`office/${id.toString()}/${getToken()}`);
        }
        break;
      case 'createFolder':
        dispatch(setState({ showPopup: 'createFolder' }));
        break;

      case 'detailsPanel':
        dispatch(toggleDetailsPanel());
        break;

      case 'rename':
        dispatch(setState({ showPopup: 'edit' }));
        break;

      case 'uploadFolderImage':
        dispatch(setState({ showPopup: 'uploadFolderImage' }));
        break;

      case 'share':
        dispatch(setState({ showPopup: 'share' }));
        break;

      case 'permissions':
        dispatch(setState({ showPopup: 'permissions' }));
        break;

      case 'delete':
        dispatch(setState({ showPopup: 'remove' }));
        break;

      default:
        break;
    }

    dispatch(hideContextMenu());
  };

  handleClickShowPanel = () => {
    const { dispatch } = this.props;
    const { showDetailsPanel } = this.props.UI;
    dispatch(setState({ showDetailsPanel: !showDetailsPanel, showContextMenu: false }));
  };

  componentDidMount() {
    const that = this;
    const { drive } = this.props;
    let permissions = [];

    if (drive && drive.activeItem && drive.activeItem.permissions) {
      permissions = drive.activeItem.permissions;
    }

    this.setState({
      permissions,
      isOwner: isOwner(that.props),
    });
  }

  componentDidUpdate() {
    let element = document.querySelector('#CONTEXT_MENU');
    if (!element) return;

    const { contextMenu } = this.props.UI;

    var rect = element.getBoundingClientRect();
    let width = rect.width;
    let height = rect.height;

    let top = height + contextMenu.y > window.innerHeight ? contextMenu.y - height : contextMenu.y;
    let left = width + contextMenu.x > window.innerWidth ? contextMenu.x - width : contextMenu.x;

    top += window.scrollY;

    if (this.state.position.top === top && this.state.position.left === left) return;

    this.setState({
      position: {
        top: top,
        left: left,
      },
    });
  }

  render() {
    const { UI, canCreate } = this.props;
    const { showDetailsPanel, contextMenu } = UI;
    const { item } = contextMenu;

    const isOwner = this.state.isOwner;
    const permissions = this.state.permissions;

    const editableExtensions = ['docx', 'xlsx'];
    const isEditable = item && item.details && item.details.extension && editableExtensions.includes(item.details.extension) ? true : false;

    return (
      <div className="context-menu" id="CONTEXT_MENU" style={this.state.position}>
        {item ? (
          <>
            {(isOwner || getPermission(permissions, 'read')) && item.type && isEditable && (
              <div className="context-menu__item context-menu__item--edit" onClick={() => this.handleClickItem('editor')}>
                Otevřít soubor
              </div>
            )}

            {(isOwner || getPermission(permissions, 'read')) && item.type && (
              <div className="context-menu__item context-menu__item--download" onClick={() => this.handleClickItem('download')}>
                Stáhnout soubor
              </div>
            )}

            {(isOwner || getPermission(permissions, 'read')) && !item.type && (
              <div className="context-menu__item context-menu__item--open" onClick={() => this.handleClickItem('open')}>
                Otevřít složku
              </div>
            )}

            <div className="context-menu__item context-menu__item--detail" onClick={() => this.handleClickItem('detailsPanel')}>
              {!showDetailsPanel ? 'Zobrazit' : 'Skrýt'} podrobnosti
            </div>

            {(isOwner || getPermission(permissions, 'update_delete')) && (
              <div className="context-menu__item context-menu__item--rename" onClick={() => this.handleClickItem('rename')}>
                Přejmenovat
              </div>
            )}

            {(isOwner || getPermission(permissions, 'update_delete')) && !item.type && (
              <div className="context-menu__item context-menu__item--folder-image" onClick={() => this.handleClickItem('uploadFolderImage')}>
                Nastavit obrázek složky
              </div>
            )}

            {(isOwner || getPermission(permissions, 'owner')) && (
              <div className="context-menu__item context-menu__item--permissions" onClick={() => this.handleClickItem('permissions')}>
                Nastavení práv
              </div>
            )}

            {(isOwner || getPermission(permissions, 'share')) && (
              <div className="context-menu__item context-menu__item--share" onClick={() => this.handleClickItem('share')}>
                Sdílet
              </div>
            )}

            {(isOwner || getPermission(permissions, 'update_delete')) && (
              <>
                <div className="context-menu__item-divider"></div>
                <div className="context-menu__item context-menu__item--trash" onClick={() => this.handleClickItem('delete')}>
                  Odstranit
                </div>
              </>
            )}
          </>
        ) : (
          <>
            {canCreate ? (
              <div className="context-menu__item context-menu__item--add-folder" onClick={() => this.handleClickItem('createFolder')}>
                Vytvořit novou složku
              </div>
            ) : (<div className="context-menu__item context-menu__item--none">Není k dispozici žádná akce</div>)}
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default compose(withRouter, withTranslation('translations'), connect(mapStateToProps, mapDispatchToProps))(ContextMenu);
